.home {
    background-color: #f6f9fd;
}

.pageBg {
    background-color: #f6f9fd;
    min-height: 1572px !important;
    min-width: 1440px !important;
}

.pageBg .userlist {
    border: 1px solid black;
    height: 100px;
    padding-top: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: white;
    font-size: 20px;
    font-family: Rubik;
    padding-left: 25px;
    padding-top: 30px
}

