.sidebanner .left {
    padding: 50px;
    text-align: left;
}

.sidebanner .left h1 span {
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #96fced;
    text-align: left;
    font-family:Avenir
}

.sidebanner .left h1 {
    width: 455.4px;
    height: 120px;
    font-size: 30px;
    /* font-weight: bold; */
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    text-align: left;
    margin-top: 200px;
    font-family:Avenir
}

