.login{
    min-height:100vh;
    background-image: url("./../../assets/images/group-3.png");
    background-size:cover
}


.login .right .form {
    width: 602px;
    min-height: 100vh;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    margin-left: -80px
}