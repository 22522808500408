.loginform {
    padding: 60px;
}

.mb50 {
    margin-top: 50px;
}

.loginform .heading {
    margin-top: 100px;
    text-align: left;
}

.heading h1 {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303030;
}

.heading p {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303030;
    font-family:Avenir
}

.heading h1 span {
    color: #2b7ed1;
}

.mbform {
    margin-top: 70px;
}

.form-control {
    border: 1px solid white !important;
    border-bottom: solid 1px rgba(208, 214, 230, 0.85) !important;
    margin-bottom: 60px;
    border-radius: 0px !important;
    padding-left: 0px !important;
}

.form-control:focus {
    border: 1px solid white !important;
    border-bottom: solid 2px #2b7ed1 !important;
    box-shadow: none !important;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #686868;
}

.input-group-text {
    background-color: transparent !important;
    border: 1px solid white !important;
    border-bottom: solid 1px rgba(208, 214, 230, 0.85) !important;
    border-radius: 0px !important;
}

.forgot {
    opacity: 0.9;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: 0.2px;
    color: #2154a6;
    margin-top: 15px;
}


.loginbtn {
    width: 141px;
    height: 44px;
    opacity: 0.85;
    border-radius: 2px;
    box-shadow: 2px 2px 3px 0 rgba(23, 75, 162, 0.49);
    background-image: linear-gradient(to top, #1f9cf8, #03045c);
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none !important;
}