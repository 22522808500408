.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    opacity: 0.15;
    border-radius: 17px;
    background-color: #ffffff;
    color: black
}

.search {
    background-color: transparent !important;
    opacity: 0.26;
    border: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.85) !important;
    font-family: Avenir;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

#inputGroupPrepend {
    border: none !important;
}

input:focus {
    box-shadow: none !important;
    border: none !important
}

.Navigation ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}


.input-group-text {
    border: none !important;
}

.fa-search {
    color: #ffffff;
}

.Navigation .form-control {
    border: none;
}